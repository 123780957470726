import React from "react"
import Helmet from 'react-helmet';
import modz from './modz.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faTwitch } from '@fortawesome/free-brands-svg-icons';


export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {'DatModz Gamer Extraordinaire'}
      </title>
      <meta name="description" content="Introducing DatModz gamer and beard lover." />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@datmodz" />
      <meta
        name="twitter:title"
        content="DatModz"
      />
      <meta
        name="twitter:image"
        content={modz}
      />
      <meta name="twitter:description" content="Introducing DatModz gamer and beard lover." />
    </Helmet>
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100wh' }}>
      <h1 style={{ textAlign: 'center', fontFamily: 'Hind' }}>DatModz</h1>
      <div style={{ margin: '0 auto' }}>
        <img src={modz} style={{ borderRadius: '50%' }}/>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3em' }}>
        <a href="https://www.twitter.com/datmodz">
          <FontAwesomeIcon icon={faTwitter} size="6x"/>
        </a>
        <a href="https://www.twitch.tv/datmodz">
          <FontAwesomeIcon icon={faTwitch} size="6x"/>
        </a>
      </div>
    </div>
  </div>
);
